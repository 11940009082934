import React from 'react';
import logo_sec from '../../images/logo.png';
import styles from "./free-portfolio-product.module.css";

import rota_img from "../../images/rota-img.png";
import gif_new from "../../images/gif-new.gif";
import first_img from "../../images/first-img.png";
import second_img from "../../images/second-img.png";
import second_end_img from "../../images/second-end-img.png";
import center_img from "../../images/center-img.png";
import third_img from "../../images/third-img.png";
import third_icn from "../../images/third-icn.png";
import third_end_img from "../../images/third-end-img.png";
import third_end_icn from "../../images/third-end-icn.png";
import last_img from "../../images/last-img.png";
import center_img_1 from "../../images/center-img-1.png";
import center_img_2 from "../../images/center-img-2.png";
import first_img_pos from "../../images/first-img-pos.png";
import cam_land_gif from "../../images/cam-land-gif.gif";
import perf_img from "../../images/perf-img.png";
import vanity_img from "../../images/vanity-img.png";
import grp_img from "../../images/grp-img.png";
import cup_tea_img from "../../images/cup-tea-img.png";
import bag_img from "../../images/bag-img.png";
import shoe_img from "../../images/shoe-img.png";
import prd_1 from "../../images/prd-1.png";
import prd_2 from "../../images/prd-2.png";
import prd_3 from "../../images/prd-3.png";
import prd_4 from "../../images/prd-4.png";
import shh_1 from "../../images/shh-1.png";
import shh_2 from "../../images/shh-2.png";
import shh_3 from "../../images/shh-3.png";
import shh_4 from "../../images/shh-4.png";
import shh_5 from "../../images/shh-5.png";
import cate_play from "../../images/cate-play.png";
import ps_shot_1 from "../../images/ps-shot-1.png";
import ps_shot_2 from "../../images/ps-shot-2.png";
import ps_shot_3 from "../../images/ps-shot-3.png";
import ps_shot_4 from "../../images/ps-shot-4.png";
import lim_upper from "../../images/lim-upper.png";
import lim_shirt from "../../images/lim-shirt.png";
import lim_down from "../../images/lim-down.png";


const ProductPhotoshoot = () => {
  return (
    <>
        <div className={styles.shoot_land_bg}>

            {/* <section className={styles.logo_land_sec_new}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className={styles.logo_land_sec_new_inner}>
                                <img src={logo_sec} alt="" title="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className={styles.unevil_sec_new_des}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className={styles.unevil_sec_new_des_inner}>
                                <div className={styles.unevil_sec_new_des_inner_head}>
                                    <h6 className="wow zoomIn" data-wow-duration="1.2s">Unveil the Beauty of Your Products with Our Professional Photography Services</h6>
                                </div>
                                <div className={`${styles.unevil_sec_new_des_inner_body} ${styles.desk_ver}`}>
                                    <div className={`${styles.unevil_center_box} ${styles.sectn}`}>
                                        <div className={`${styles.pos_icn_center} wow fadeInLeftBig`} data-wow-duration="1s">
                                            <a href="#lim_id" className="">
                                                <img src={rota_img} className={styles.rota_img}  />
                                                <div className={styles.get_prdoct_rot}>
                                                    <img src={gif_new} />
                                                </div>
                                            </a>                                
                                        </div>
                                        <img src={first_img} className="wow fadeInLeftBig" data-wow-duration="1.5s" />
                                    </div>
                                    <div className={styles.unevil_middle_box}>
                                        <div className={styles.unevil_center_box}>
                                            <img src={second_img} className="wow fadeInLeftBig" data-wow-duration="2.0s" />
                                        </div>
                                        <div className={styles.unevil_center_box}>
                                            <img src={second_end_img} className="wow fadeInLeftBig" data-wow-duration="2.5s" />
                                        </div>
                                    </div>
                                    <div className={`${styles.unevil_center_box} wow zoomIn`} data-wow-duration="3.0s">
                                        <img src={center_img} />
                                    </div>
                                    <div className={styles.unevil_middle_box}>
                                        <div className={styles.unevil_center_box}>
                                            <img src={third_img} className="wow fadeInRightBig" data-wow-duration="2.3s" />
                                            <img src={third_icn} className={`${styles.icn_abso} wow fadeInRightBig`} data-wow-duration="2.4s" />
                                        </div>
                                        <div className={styles.unevil_center_box}>
                                            <img src={third_end_img} className="wow fadeInRightBig" data-wow-duration="2.6s" />
                                            <img src={third_end_icn} className={`${styles.icn_abso_end} wow fadeInRightBig`} data-wow-duration="2.8s" />
                                        </div>
                                    </div>
                                    <div className={`${styles.unevil_center_box} ${styles.right_box}`}>
                                        <img src={last_img} className="wow fadeInRightBig" data-wow-duration="3.0s" />
                                    </div>
                                </div>

                                
                                <div className={`${styles.unevil_sec_mobile_view} ${styles.mob_ver}`}>
                                    <div className="swiper-container">
                                        <div className="swiper-wrapper">
                                            <div className={`${styles.unevil_sec_mobile_view_inner_single} swiper-slide`}>
                                                <img src={center_img} />
                                            </div>
                                            <div className={`${styles.unevil_sec_mobile_view_inner_single} swiper-slide`}>
                                                <img src={center_img_1} />
                                            </div>
                                            <div className={`${styles.unevil_sec_mobile_view_inner_single} swiper-slide`}>
                                                <img src={center_img_2} />
                                            </div>
                                        </div>
                                        <div className="swiper-pagination"></div>
                                    </div>   
                                    <img src={first_img_pos} className={styles.fir_img_new} />            
                                </div>

                                <div className={styles.review_sec_new_list}>
                                    <div className={`${styles.review_sec_new_list_single} ${styles.sectn}`}>
                                        <p className="wow fadeInLeftBig" data-wow-duration="2.0s">Our professional photographers are here to provide you with high-quality product shots that showcase your products in the best light possible.</p>
                                        <a href="#lim_id" className={`${styles.get_start_btn} wow fadeInLeftBig`} data-wow-duration="2.0s">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.photoshoot_inceludes}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className={styles.photoshoot_inceludes_inner}>
                                <div className={styles.photoshoot_inceludes_single}>
                                    <div className={styles.photoshoot_inceludes_single_left}>
                                        <h5 className="wow fadeInLeftBig" data-wow-duration="2.0s">
                                        <img src={cam_land_gif} className={styles.cam_img_te} /> Product Photoshoot Includes:</h5>
                                        <div className={`${styles.photoshoot_inceludes_single_left_box} wow fadeInLeftBig`} data-wow-duration="1.5s">
                                            <ul>
                                                <li>Over 1000+ All talent photographers across india!</li>
                                                <li>Guaranteed on-time delivery of photography of photography projects</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={styles.photoshoot_inceludes_single_right}>
                                        <img src={perf_img} className="wow fadeInRightBig" data-wow-duration="1.5s" />
                                        <img src={vanity_img} className={`${styles.vanity_img} wow fadeInRightBig`} data-wow-duration="1.5s" />
                                        <img src={grp_img} className={`${styles.grp_img} wow fadeInRightBig`} data-wow-duration="1.5s" />
                                    </div>
                                </div>
                                <div className={`${styles.photoshoot_inceludes_single} ${styles.new_align_box}`}>
                                    <div className={styles.photoshoot_inceludes_single_right}>
                                        <img src={cup_tea_img} className={`${styles.cup_tea} wow fadeInLeftBig`} data-wow-duration="1.5s" />
                                        <img src={bag_img} className="wow fadeInLeftBig new_bag" data-wow-duration="1.5s" />
                                        <img src={shoe_img} className={`${styles.shoe_img} wow fadeInLeftBig`} data-wow-duration="1.5s" />
                                    </div>
                                    <div className={styles.photoshoot_inceludes_single_left}>
                                        <div className={`${styles.photoshoot_inceludes_single_left_box} wow fadeInRightBig`} data-wow-duration="1.5s">
                                            <ul>
                                                <li>Team of editing experts at your service</li>
                                                <li>The digital stage where culinary creations become star of the show</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.budget_sec_new_btn} ${styles.new_btn_place} ${styles.sectn}`}>
                                    <a href="#lim_id" className="wow zoomIn" data-wow-duration="2s">Claim Offer Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.product_shoot_sec_cate}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className={styles.product_shoot_sec_cate_inner}>
                                <div className={`${styles.product_shoot_sec_cate_inner_head} wow zoomIn`} data-wow-duration="3s">
                                    <h6>Categories of Product shoots</h6>
                                </div>
                                <div className={styles.product_shoot_sec_cate_inner_body}>
                                    <a href="#" data-toggle="modal" data-target="#e_comm">
                                        <div className={`${styles.product_shoot_sec_cate_inner_body_single} wow fadeInLeftBig`} data-wow-duration="1.8s">
                                            <img src={prd_1} alt="" title="" />
                                            <div className={styles.product_single_cont}>
                                                <h5>E-commerce Photoshoot</h5>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#" data-toggle="modal" data-target="#Food">
                                        <div className={`${styles.product_shoot_sec_cate_inner_body_single} wow fadeInLeftBig`} data-wow-duration="1.8s">
                                            <img src={prd_2} alt="" title="" />
                                            <div className={styles.product_single_cont}>
                                                <h5>Food Photography</h5>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#" data-toggle="modal" data-target="#Lifestyle">
                                        <div className={`${styles.product_shoot_sec_cate_inner_body_single} wow fadeInRightBig`} data-wow-duration="1.8s">
                                            <img src={prd_3} alt="" title="" />
                                            <div className={styles.product_single_cont}>
                                                <h5>Lifestyle shoot</h5>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#" data-toggle="modal" data-target="#Designer">
                                        <div className={`${styles.product_shoot_sec_cate_inner_body_single} wow fadeInRightBig`} data-wow-duration="1.8s">
                                            <img src={prd_4} alt="" title="" />
                                            <div className={styles.product_single_cont}>
                                                <h5>Designer Clothing shoot</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.budget_sec_new_sec}>
                <div className="container-fluid">
                    <div className="row"> 
                        <div className="col-md-12 col-lg-12">
                            <div className={styles.budget_sec_new_sec_inner}>
                                <div className={`${styles.product_shoot_sec_cate_inner_head} wow zoomIn`} data-wow-duration="3s">
                                    <h6>Define Your Budget: For Personalized Product Photoshoots</h6>
                                </div>
                                <div className={styles.budget_sec_new_sec_inner_body}>
                                    <ul>
                                        <li className="wow fadeInLeftBig" data-wow-duration="1s">Tell Us Your Budget</li>
                                        <li className="wow fadeInLeftBig" data-wow-duration="1s">Discuss Your Goals</li>
                                        <li className="wow fadeInRightBig" data-wow-duration="1s">Tailored Photoshoot Plan</li>
                                        <li className="wow fadeInRightBig" data-wow-duration="1s">Delivery and Beyond</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.shhots_for_sec_new}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className={styles.shhots_for_sec_new_inner}>
                                <div className={`${styles.product_shoot_sec_cate_inner_head} wow zoomIn`} data-wow-duration="2s">
                                    <h6>Shoots for</h6>
                                </div>
                                <div className={styles.shhots_for_sec_new_inner_body}>
                                    <div className={`${styles.shhots_for_sec_single} wow fadeInLeftBig`} data-wow-duration="1.5s">
                                        <img src={shh_1} />
                                    </div>
                                    <div className={`${styles.shhots_for_sec_single} wow fadeInLeftBig`} data-wow-duration="1.5s">
                                        <img src={shh_2} />
                                    </div>
                                    <div className={`${styles.shhots_for_sec_single} wow zoomIn`} data-wow-duration="1.5s">
                                        <img src={shh_3} />
                                    </div>
                                    <div className={`${styles.shhots_for_sec_single} wow fadeInRightBig`} data-wow-duration="1.5s">
                                        <img src={shh_4} />
                                    </div>
                                    <div className={`${styles.shhots_for_sec_single} wow fadeInRightBig`} data-wow-duration="1.5s">
                                        <img src={shh_5} />
                                    </div>
                                </div>
                                <div className={`${styles.budget_sec_new_btn} ${styles.sectn}`}>
                                    <a href="#lim_id" className="wow zoomIn" data-wow-duration="2s">Claim Offer Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`${styles.happy_clients_sec} ${styles.new_happy}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className={`${styles.happy_clients_sec_main}`}>
                                <div className={`${styles.shoots_for_sec_new_head} wow zoomIn`} data-wow-duration="1.5s">
                                    <h6>Happy Clients</h6>
                                </div>
                                <div className={styles.happy_clients_sec_main_body}>
                                    <div className={`${styles.happy_clients_sec_main_body_single} wow fadeInLeftBig`} data-wow-duration="1.5s">
                                        <div className={styles.happy_clients_sec_main_body_single_img}>
                                            <a href="videos/reena-1.mp4" className="popup-vimeo">
                                                <img src={ps_shot_1} />
                                                <img src={cate_play} className={styles.cate_play_icn} />
                                            </a>
                                        </div>
                                        <div className={styles.happy_clients_sec_main_body_single_cont}>
                                            <h6>Reena Maurya</h6>
                                            <p>Owner - Ekansha collection</p>
                                        </div>
                                    </div>
                                    <div className={`${styles.happy_clients_sec_main_body_single} wow fadeInLeftBig`} data-wow-duration="1.5s">
                                        <div className={styles.happy_clients_sec_main_body_single_img}>
                                            <a href="videos/Vivek-1.mp4" className="popup-vimeo">
                                                <img src={ps_shot_2} />
                                                <img src={cate_play} className={styles.cate_play_icn} />
                                            </a>
                                        </div>
                                        <div className={styles.happy_clients_sec_main_body_single_cont}>
                                            <h6>Vivek</h6>
                                            <p>Owner - Crystal bracelets</p>
                                        </div>
                                    </div>
                                    <div className={`${styles.happy_clients_sec_main_body_single} wow fadeInRightBig`} data-wow-duration="1.5s">
                                        <div className={styles.happy_clients_sec_main_body_single_img}>
                                            <a href="videos/friticious-reel.mp4" className="popup-vimeo">
                                                <img src={ps_shot_3} />
                                                <img src={cate_play} className={styles.cate_play_icn} />
                                            </a>
                                        </div>
                                        <div className={styles.happy_clients_sec_main_body_single_cont}>
                                            <h6>Prachi</h6>
                                            <p>Founder -Fruitelicious</p>
                                        </div>
                                    </div>
                                    <div className={`${styles.happy_clients_sec_main_body_single} wow fadeInRightBig`} data-wow-duration="1.5s">
                                        <div className={styles.happy_clients_sec_main_body_single_img}>
                                            <a href="videos/Firme-1.mp4" className="popup-vimeo">
                                                <img src={ps_shot_4} />
                                                <img src={cate_play} className={styles.cate_play_icn} />
                                            </a>
                                        </div>
                                        <div className={styles.happy_clients_sec_main_body_single_cont}>
                                            <h6>Prakash Patel</h6>
                                            <p>Director - Firme Bags</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.budget_sec_new_btn} ${styles.sectn}`}>
                                    <a href="#lim_id" className="wow zoomIn" data-wow-duration="3s">Get your free Photoshoot</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.limited_time_only} id={styles.lim_id}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className={styles.limited_time_only_inner}>
                                <div className={styles.limited_time_only_left}>
                                    <div className={styles.limited_time_only_left_head}>
                                        <h6 className="wow fadeInLeftBig" data-wow-duration="1.5">Limited Time Only! </h6>
                                        <p className="wow fadeInLeftBig" data-wow-duration="1.5s">Free Product Photography for first 5 Items</p>
                                    </div>
                                    <div className={styles.limited_time_only_left_body}>
                                        <div className={styles.limited_time_only_left_grid}>
                                            <div className={`${styles.limited_time_only_left_single} wow fadeInLeftBig`} data-wow-duration="1.5s">
                                                <input type="text" name="" placeholder="Enter First Name" />
                                            </div>
                                            <div className={`${styles.limited_time_only_left_single} wow fadeInLeftBig`} data-wow-duration="1.6s">
                                                <input type="text" name="" placeholder="Enter Last Name" />
                                            </div>
                                            <div className={`${styles.limited_time_only_left_single} wow fadeInLeftBig`} data-wow-duration="1.7s">
                                                <input type="text" name="" placeholder="Enter Email ID" />
                                            </div>
                                            <div className={`${styles.limited_time_only_left_single} wow fadeInLeftBig`} data-wow-duration="1.8s">
                                                <input type="tel" id="mobile_number_new" className="" name="mobile_number_new" onkeypress="return isNumberKey(event);" value="" placeholder="Enter Phone Number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" />
                                            </div>
                                        </div>
                                        <div className={`${styles.limited_time_only_left_single} wow fadeInLeftBig`} data-wow-duration="1.9s">
                                            <textarea rows="1" placeholder="Describe your Product"></textarea>
                                        </div>
                                        <div className={`${styles.submit_btn_limited} wow fadeInLeftBig`} data-wow-duration="2.1s">
                                            <a href="#">Submit</a>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.limited_time_only_right}>
                                    <img src={lim_upper} className={`${styles.lim_upper} wow fadeInRightBig`} data-wow-duration="1.5s" />
                                    <img src={lim_shirt} className="wow fadeInRightBig" data-wow-duration="1.5s" />
                                    <img src={lim_down} className={`${styles.lim_down} wow fadeInRightBig`} data-wow-duration="1.5s" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </>
  )
}

export default ProductPhotoshoot