import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductPhotoshoot from './Pages/LandingPages/ProductPhotoshoot';


const AppContent = () => {
  
  return (
    <>
      <Routes>
        <Route path="/" element={<ProductPhotoshoot />} />
      </Routes>
    </>
  );
};

const App = () => (
      <Router>
          <AppContent />     
      </Router>
);
export default App;
